
import React, { useState, useEffect } from 'react';
import { ImQuotesLeft } from 'react-icons/im';
import { animated } from 'react-spring';
import innerImage from '../asstes/Promotional Section.png';

// Arrow Button component
const ArrowButton = ({ direction = "right", onClick, isVisible }) => (
    <button
        onClick={onClick}
        className={`bg-white  text-gray-800 rounded-full p-2 -ml-4 -mr-4 shadow-md hover:shadow-lg transition duration-300 ease-in-out 
      ${isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        style={{
            width: '40px',
            height: '40px',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 10,
            transition: 'opacity 0.3s ease', // Smooth transition for visibility
            [direction === "left" ? 'left' : 'right']: '16px'
        }}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className={`w-6 h-6 ${direction === "left" ? 'rotate-180' : ''}`} // Flip the arrow icon for the left direction
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
            />
        </svg>
    </button>
);

const Testimonials = () => {
    const testimonials = [
        {
            text: "The product is really amazing and from morning, I have drank the beetroot malt two times. It’s really amazing and my family members enjoyed the product. Good output. Keep giving us some more healthy products like this.",
            author: "Mr. MANIKANDAN"
        },
        {
            text: "Mam I have tried your beetroot malt and murungai idly podi it was very nice That beetroot malt has exceeded my expectations and has become a part of my diet. Thank you so much, will order soon again. All the best for your work and keep going 😊😊",
            author: "Dr. Khaviya"
        },
        {
            text: "Hi Sir… Actually I thought of telling you after a month of experiencing the product… As of now I’m feeling great and that’s a good stuff for people… this beetroot malt is so good.",
            author: "Mr. Rahul"
        },
        {
            text: "Hi Sir… Actually I thought of telling you after a month of experiencing the product… As of now I’m feeling great and that’s a good stuff for people… this beetroot malt is so good.",
            author: "Mr. Rahul"
        },

    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    const isBlurred = (index) => index !== currentIndex;

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
    };

    const positionValue = (index) => {
        if (index === currentIndex) {
            return "translateX(0) scale(1.1)";
        }
        if (index === (currentIndex - 1 + testimonials.length) % testimonials.length) {
            return "translateX(-100%) scale(0.85)";
        }
        if (index === (currentIndex + 1) % testimonials.length) {
            return "translateX(100%) scale(0.85)";
        }
        return "translateX(200%)"; 
    };

    const isVisible = (index) => {
        return (
            index === currentIndex ||
            index === (currentIndex - 1 + testimonials.length) % testimonials.length ||
            index === (currentIndex + 1) % testimonials.length
        );
    };


    return (
        <div className="bg-[#181818] text-white py-[20px]"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="px-[20px] md:px-[80px]">
                <h2 className="text-center text-[28px] md:text-[44px] font-semibold mb-[20px]">
                    See What Others Are Saying
                </h2>

                {/* Dashed Line */}
                <div className="mx-auto border-dashed border-t-2 border-[#ECBC56] w-[230px] md:w-[570px] mb-[20px]"></div>

                <p className="text-center text-white md:text-[32px] text-[12px] font-normal md:mb-[10px] mb-[10px]">
                    Authentic stories of satisfaction and experience, fostering enduring trust with each interaction.
                </p>

                {/* Testimonial Cards Display */}
                <div className="relative flex justify-center items-center overflow-hidden">
                    <ArrowButton
                        direction="left"
                        onClick={handlePrev}
                        isVisible={isHovered || true} // Show left button on hover or by default for mobile
                    />
                    <div className="flex justify-center items-center relative w-full h-[280px] md:h-[400px]">
                        {testimonials.map((testimonial, index) => (
                            <animated.div
                                key={index}
                                className={`absolute testimonial-item bg-[#111B2D] p-2 sm:p-8 rounded-lg shadow-lg mx-2 flex-shrink-0 w-[70%] md:w-[40%] text-center transition-transform duration-500 ease-in-out`}
                                style={{
                                    transform: positionValue(index),
                                    zIndex: index === currentIndex ? 1 : 0,
                                    opacity: isVisible(index) ? 1 : 0,
                                    visibility: isVisible(index) ? "visible" : "hidden", // Hide off-screen cards
                                    transition: 'transform 0.5s ease, opacity 0.5s ease',
                                    filter: isBlurred(index) ? 'blur(1px)' : 'none',
                                    background: isBlurred(index)
                                        ? 'linear-gradient(180deg, rgba(255,255,255,0.1), rgba(255,255,255,0.1))'
                                        : '#222222',
                                }}
                            >
                                <blockquote className="flex flex-col text-[11px] sm:text-base lg:text-lg italic mb-4">
                                    <ImQuotesLeft className="w-6 h-6 sm:w-8 sm:h-8 text-yellow-500 mb-2" />
                                    <p className='text-[14px] md:text-[25px]'>{testimonial.text}</p>
                                </blockquote>
                                <p className="text-right text-sm sm:text-base">- {testimonial.author}</p>
                            </animated.div>
                        ))}

                    </div>
                    <ArrowButton
                        direction="right"
                        onClick={handleNext}
                        isVisible={isHovered || true} // Show right button on hover or by default for mobile
                    />
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
